<template>
  <div class="container-fluid home-page-B-containter rx-wrapper pl-0 pr-0" style="background: #ffffff">
    <nav class="navbar navbar-expand-lg navbar-light" style="background: #ffffff">
      <a class="col-2 navbar-brand" href="/" style="margin-top:-22px;">
        <img src="@/assets/rxix-logo.png" class="img-fluid" alt="RxIx" />
      </a>
      <button class="navbar-toggler d-none" type="button" data-toggle="collapse" data-target="#navigations-02">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-md-center" id="navigations-02">
        <div class="row w-100">
          <div class="col-11 pr-lg-0">

            <ul class="navbar-nav justify-content-end rxix-menu">
              <li class="col col-lg-2 nav-item nav" >
                <a href="/public">
                    <h4 data-toggle="modal" data-target="#publicModal" class="nav-link rxix-menu"
                        style="color: #000; font-size: 24px;" :class="{ 'CurrentPageActive': isRouteActive('/public') }">Public
                      </h4>
                  </a>
              </li>
              <li class="col col-lg-2 nav-item nav" >
                <a href="/doctor">
                <h4  class="nav-link cursor-pointer" style="color: #000; font-size: 24px;" :class="{ 'CurrentPageActive': isRouteActive('/doctor') }">Doctors
                </h4>
            </a>
              </li>
              <li class="col col-lg-2 nav-item nav">
                <a href="/aboutus">
                <h4 class="nav-link cursor-pointer" style="color: #000; font-size: 24px;"  :class="{ 'CurrentPageActive': isRouteActive('/aboutus') }">About Us
                 </h4>
                 </a>
                </li>
              <!-- <li class="nav-item nav" style="width:6%">
                <a
                  href="/contactus"
                  class="nav-link"
                  >
                <img src="@/assets/images/smartphone.png" style="width:39px;"></a>
              </li>
              <li class="nav-item nav">
                <h4><a
                  href=""
                  class="nav-link cursor-pointer"
                  >984 xxx xxxx
                </a></h4>
              </li> -->

              <!-- <li class="nav-item">
                <a
                  href=""
                  data-toggle="modal"
                  data-target="#mySigninModal"
                  id="user_signup"
                  target="_blank"
                  class="nav-link rxix-menu signup_block"
                  >Sign Up
                </a>
              </li> -->
              <li class="nav-item">
                <!-- <a
                  class="nav-link cursor-pointer"
                  data-bs-toggle="modal"
                  data-bs-target="#signInModal"
                  >Sign In
                </a> -->
                <button class="btn btn-blue-color text-white sign1" @click="cardSign()">Sign In</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>

    <!-- <hr style="border:2px solid #00979e"> -->
    <!--signIn Modal -->


    <div class="modal fade" id="signInModal" tabindex="-1" aria-labelledby="signInModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="signInModalLabel" style="color:#00979e;">Sign In</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body p-4">
            <form>
              <div class="form-group my-3">
                <label class="text-color form">Registered Mail ID *</label>
                <input type="email" v-model="emailId" name="email_id" id="email_id" class="form-control" />
              </div>
              <div class="form-group my-3">
                <label class="text-color form">Password *</label>
                <input type="password" v-model="password" name="password" id="password" class="form-control" />
              </div>
              <div class="form-group my-3">
                <label class="text-color">
                  <router-link to="/forgot-password" style="text-decoration:none;color:#00979e;">Forgot password ?
                  </router-link>
                </label>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" @click="signIn()" class="btn text-white btn-blue-color" style="width:100%;">
              Sign In
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- end signIn modal -->
  </div>
  <!-- <SignIn @isHide=signupPopupStatus($event) v-if="issubmit"></SignIn> -->
</template>

<script >
// import SignIn from "../../views/presignPage/signin.vue"
import { defineComponent } from "vue";
// import axios from "axios";
import "../../css/app.css";
export default defineComponent({
  components: {
    // SignIn
  },
  name: "Header",
  props: {},
  data() {
    return {
      emailId: "",
      password: "",
      isLoginState: "",
      currentPath: "",
      // issubmit:false
    };
  },
  methods: {
    isRouteActive(routePath) {
      return this.$route.path === routePath;
    },
    signIn() {
      if (this.emailId == "rajeshprasath.r@cloudripples.io" && this.password == "12345") {
        // this.$router.push('/doctor/index')
        window.location.href = "/doctor/index";
        let modalBackground = document.querySelector(".modal-backdrop");
        if (modalBackground) {
          modalBackground.remove();
        }
      } else {
        if (this.emailId == "rajesh@gmail.com" && this.password == "54321") {
          // this.$router.push('/user/index')
          window.location.href = "/user/index";
          let modalBackground = document.querySelector(".modal-backdrop");
          if (modalBackground) {
            modalBackground.remove();
          }
        } else {
          if (this.emailId == "prasath@gmail.com" && this.password == "00000") {
            // this.$router.push('/admin/index')
            window.location.href = "/admin/index";
            let modalBackground = document.querySelector(".modal-backdrop");
            if (modalBackground) {
              modalBackground.remove();
            }
          }
        }
      }
    },
    cardSign() {
      this.$router.push({ path: '/signup'});
    }
  },
  created: function () {
    this.currentPath = this.$route.path.split('/')[2]
  },
});
</script>
<style scoped>
.CurrentPageActive{
  border-bottom: 2px solid #34989f;
  width: fit-content;
}
.container-fluid.home-page-B-containter.rx-wrapper.pl-0.pr-0 {
  background: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.navbar-nav.justify-content-end.rxix-menu {
  padding-right: 12px;
  justify-content: flex-end !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
  padding-left: 22px;
  float: right;
  margin-right: 10px;
}

li.nav-item.nav {
  padding-right: 20px;
}

button.btn.btn-blue-color.text-white {
  width: 100%;
  font-size: large;
  font-weight: bold;
}
</style>